<template>
  <div>
    <b-row class="mb-2 align-items-center">
      <b-col cols="12" lg="10">
        <div class="d-flex pl-2 align-items-center">
          <b-img class="filter-image" :class="filter.sort_type == 'asc' ? 'rotate-180' : ''"
            :src="require('@/assets/images/icons/Icon-order-list-down.svg')" alt="Icon-order-list-down" role="button"
            @click="
              filter.sort_type == 'asc'
                ? (filter.sort_type = 'desc')
                : (filter.sort_type = 'asc')
              " />
          <div class="search__input pl-2 pr-0 mr-0">
            <b-input-group>
              <b-form-input v-model="filter.search" placeholder="" />
              <b-input-group-append is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="pl-2">
            <BFormDatepicker v-model="filter.register_date" locale="in" placeholder="Tanggal Registrasi" />
          </div>
          <div class="pl-2">
            <b-button variant="danger" @click="filter = {}">
              Reset
            </b-button>
          </div>
        </div>
      </b-col>
      <b-col cols="12" lg="2">
        <div>
          <b-button variant="primary" @click="exportReport">
            Export Data
          </b-button>
        </div>
      </b-col>
    </b-row>

    <b-card-code no-body>
      <div class="table-responsive-sm" style="overflow-x: auto">
        <table class="table b-table table-fixed">
          <thead>
            <tr>
              <th v-for="column, index in columns" :key="index">{{ column.label }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item, index in result.data" :key="`table-${index}`">
              <td>{{ item.register_date || '-' }}</td>
              <td>{{ item.name || '-' }}</td>
              <td>{{ item.owner && item.owner.name || '-' }}</td>
              <td>
                <div class="btn-group" role="group" aria-label="Basic example">
                  <b-button variant="primary" size="sm" @click="editItem(item)">
                    Lihat Detail
                  </b-button>
                </div>
              </td>
            </tr>
            <tr v-if="result.total == 0">
              <td colspan="5" class="text-center">
                Data is empty.
              </td>
            </tr>
          </tbody>
        </table>

        <div class="row mt-1">
          <div class="col-6 pl-2">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col-6 pr-2">
            <pagination :data="result" :limit="4" align="right" @pagination-change-page="getData" />
          </div>
        </div>
      </div>
    </b-card-code>
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import {
  BRow,
  BCol,
  BFormSelect,
  VBToggle,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BButton,
  BFormDatepicker,
} from "bootstrap-vue";

// import ModalBank from '@/components/Master/Bank/Modal/ModalBank.vue'

import { VueGoodTable } from "vue-good-table";

import moment from "moment";
import FileSaver from "file-saver";
import BCardCode from '@/@core/components/b-card-code/BCardCode.vue';

export default {
  components: {
    VueGoodTable,
    BFormSelect,
    // ModalBank,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BButton,
    BRow,
    BCol,
    BFormDatepicker,
    BCardCode,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    typeTable: {
      type: String,
    },
    result: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: "",
    },
    deleteData: {
      type: Function,
    },
    getData: {
      type: Function,
    },
    createItem: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    editId: {
      type: String,
    },
  },
  data() {
    return {
      progressValue: 35,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "Tanggal Register",
          field: "register_date",
        },
        {
          label: "Nama Toko",
          field: "name",
        },
        {
          label: "Nama Pemilik",
          field: "owner.name",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: [],
      items: [],
      search: "",
      filter: {
        search: "",
        register_date: "",
        sort_type: "desc",
        per_page: 10,
      },
    };
  },
  watch: {
    filter: {
      handler(value) {
        this.$emit("filterBank", value);
      },
      deep: true,
    },
  },
  created() { },
  mounted() { },
  methods: {
    async exportReport() {
      // const queryParams = filter

      const response = await this.$http.get("/super-admin/merchant/export", {
        responseType: "blob",
        // params: queryParams,
      });

      const dateDownload = new Date();

      const timestamp = moment(dateDownload).format("YYYYMMDD");
      let fileName = "";

      fileName = `daftar-toko-${timestamp}.xlsx`;
      FileSaver.saveAs(response.data, fileName);
      // console.log(response, ' report')
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

.section__bank {
  .vgt-responsive {
    height: calc(100vh - 235px) !important;
    background-color: #fff;

    .vgt-table.bordered {
      thead th:last-child {
        text-align: left !important;
      }
    }
  }
}
</style>
